<template>
    <div>
        <div class="d-flex flex-wrap">
            <div class="mb-2 card-body">
                <h2>
                    Resultado de análisis de SEO {{ overallSeoScore }} palabra
                    clave

                    <span class="text-primary">
                        {{ keywords[0] }}
                    </span>
                </h2>
                <b-table
                    class="mt-4"
                    :fields="$data.$fieldsContent"
                    :items="assessorSeoResults"
                    :dark="activeDark"
                    :per-page="assessorSeoResults.length"
                    responsive
                    outlined
                >
                    <template #cell(rating)="data">
                        <div class="d-flex align-items-center">
                            <small class="ml-2">
                                {{ $data.$fieldScore[data.item.rating] }}
                            </small>
                            <small>
                                {{ data.item.rating }}
                            </small>
                        </div>
                    </template>

                    <template #cell(score)="data">
                        <small class="text-center font-weight-normal">
                            {{ data.item.score }}
                        </small>
                    </template>

                    <template #cell(text)="data">
                        <div class="link-warning" v-html="data.item.text"></div>
                    </template>
                </b-table>
            </div>
        </div>
        <div class="d-flex flex-wrap">
            <div class="mb-2 card-body">
                <h2>Resultado de análisis de contenido {{ overallScore }}</h2>
                <b-table
                    class="mt-4"
                    :fields="$data.$fieldsContent"
                    :items="assessorResults"
                    :dark="activeDark"
                    :per-page="assessorResults.length"
                    responsive
                    outlined
                >
                    <template #cell(rating)="data">
                        <div class="d-flex align-items-center">
                            <small class="ml-2">
                                {{ $data.$fieldScore[data.item.rating] }}
                            </small>
                            <small>
                                {{ data.item.rating }}
                            </small>
                        </div>
                    </template>

                    <template #cell(score)="data">
                        <small class="text-center font-weight-normal">
                            {{ data.item.score }}
                        </small>
                    </template>

                    <template #cell(text)="data">
                        <div class="link-warning" v-html="data.item.text"></div>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable vue/no-v-html */
import Jed from "jed";
import snarkdown from "snarkdown";
import { mapState } from "vuex";
import { ContentAssessor, Paper, SeoAssessor, interpreters } from "yoastseo";

import { esCO } from "@/common/fields/yoast-seo-translate";

export default {
    name: "SeoAnalytic",
    props: {
        keywords: {
            type: Array,
            required: true,
            default: () => []
        },
        title: {
            type: String,
            required: true,
            default: () => ""
        },
        metaDescription: {
            type: String,
            required: true,
            default: () => ""
        },
        url: { type: String, required: true, default: () => "" },
        description: {
            type: String,
            required: true,
            default: () => "<h2></h2>"
        }
    },
    data: () => ({
        marker: null,
        paper: null,
        overallScore: 0,
        overallSeoScore: 0,
        assessorResults: [],
        assessorSeoResults: [],
        seoAssessor: null,
        contentAssessor: null,
        $fieldsContent: [
            { key: "rating", label: "Rating", sortable: true },
            { key: "score", label: "Puntaje", sortable: true },
            {
                key: "text",
                label: "Descripción",
                sortable: false
            }
        ],
        $fieldScore: {
            good: "🟢",
            bad: "⚪",
            ok: "🟡"
        }
    }),
    computed: {
        ...mapState("control", ["activeDark"])
    },
    mounted() {
        this.paper = new Paper(snarkdown(this.description), {
            keyword: this.keywords[0],
            description: this.metaDescription,
            title: this.title,
            titleWidth: this.title.length,
            url: `${this.$marketplaceUrl}/${this.url}`,
            locale: "es_CO",
            permalink: ""
        });

        const i18n = new Jed(esCO);
        this.contentAssessor = new ContentAssessor(i18n);
        this.seoAssessor = new SeoAssessor(i18n);
        this.setContent();
    },
    methods: {
        setContent() {
            this.contentAssessor.assess(this.paper);
            this.seoAssessor.assess(this.paper);
            this.overallScore = this.contentAssessor.calculateOverallScore();
            this.overallSeoScore = this.seoAssessor.calculateOverallScore();

            this.assessorResults = this.contentAssessor.results.map((item) => ({
                rating: interpreters.scoreToRating(item.score),
                score: item.score,
                text: item.text
            }));

            this.assessorSeoResults = this.seoAssessor.results.map((item) => ({
                rating: interpreters.scoreToRating(item.score),
                score: item.score,
                text: item.text
            }));
        }
    }
};
</script>
