<template>
    <fragment>
        <v-md-editor
            v-model="item"
            :mode="enabled ? 'editable' : 'preview'"
            left-toolbar="undo redo | h bold italic strikethrough | ul ol table | link image-toolbar code"
            :toolbar="toolbar"
            right-toolbar="preview"
            height="340px"
            class="component-opposite"
            @input="onInput"
            @select-image="onClickSelectImage"
        ></v-md-editor>
        <image-selector
            v-model="isOpen"
            enabled-create
            message="Puedes seleccionar una imagen de cualquier tamaño"
            @input="onSelectImage"
        />
    </fragment>
</template>

<script>
import ImageSelector from "@/components/utils/ImageSelector.vue";

export default {
    name: "MarkdownEditor",
    components: { ImageSelector },
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            default: () => "",
            type: String,
            require: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change", "select-image"],
    data: () => ({
        item: "",
        toolbar: {
            "image-toolbar": {
                title: "Imagen",
                icon: "v-md-icon-img",
                menus: [
                    {
                        name: "image-select",
                        text: "Seleccionar imagen",
                        action(editor) {
                            editor.$emit("select-image");
                        }
                    },
                    {
                        name: "image-link",
                        text: "Insertar Enlace",
                        action(editor) {
                            editor.insert(function (selected) {
                                return {
                                    text: `
![Alt de imagen](http://)`,
                                    selected
                                };
                            });
                        }
                    }
                ]
            }
        },
        isOpen: false
    }),
    watch: {
        itemCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemCurrent);
    },
    methods: {
        onClickSelectImage() {
            this.isOpen = true;
        },
        onInput() {
            this.$emit("change", this.item);
        },
        setInput(value) {
            this.item = value;
        },
        onSelectImage({ alt, url }) {
            this.item = `${this.item}
<p align="center" width="100%">
    <img width="20%" src="${url}" alt="${alt}" /> 
</p>`;
            this.onInput();
        }
    }
};
</script>
